import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { PAGE_NOT_FOUND } from '../../core/constants/pageConstatnts'
import { setCurrentPage } from '../../redux/actions/currentPageAction'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { API_ENDPOINTS } from '../../core/constants/apiEndpoints'
let imageSrcPath= API_ENDPOINTS.IMAGES.IMAGES_BASE_PATH
let Error404 = imageSrcPath+"/404.svg"
class PageNotFound extends Component {

  componentDidMount() {
    const { history, location } = this.props
    if (location.path !== '/404') {
      history.push('/404')
    }
    this.props.dispatch(setCurrentPage(PAGE_NOT_FOUND))
    if (window.__IS_SSR__) {
      delete window.__IS_SSR__
    }
  }

  render() {
    return (
      <div id="pageNotFound">
        <Helmet>
          <title>Page Not Found | CredR</title>
        </Helmet>
        <section className="page-content grey-bg">
          <div className="page-container">
            <div className="errorWrapper">
              <div className="row">
                <div className="col-sm-6">
                  <img src={Error404} width="100%" alt="404 error" />
                </div>
                <div className="col-sm-6 errorContentBlock">
                  <h1 className="font-bold"><span className="colorRed">Oops!</span> Something went wrong</h1>
                  <p className="font16 font-normal">The Page you requested could not be found</p>
                  <div className="w-100 sectionBlockCustomMarginTop">
                    <Link to="/" className="btn btn-primary">Go home</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default connect()(PageNotFound)